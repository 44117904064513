import React, { useContext, useState } from 'react';
import cx from 'classnames';
import * as paths from 'paths.js';

import { useSelectedStore, useReplaceParams, useMobile } from 'hooks/index';
import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import BetaAlert from 'common/components/BetaAlert';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import useUpdateStoreDriver from 'service/hooks/verd/useUpdateStoreDriver';
import useDeleteStoreDriver from 'service/hooks/verd/useDeleteStoreDriver';
import { DeleteRider } from 'components/common/verd';
import { Button, More, Modal, Spinner, LoadingContainer, DropDown } from 'components/kit';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import MobileList from './MobileList';
import EnableVerdSetting from '../../../components/common/verd/EnableVerdSetting';
import { useFetchVerdDrivers } from '../../../service/hooks';
import { RIDER_AVAILABILITY } from '../enums';

const RidersScreen = () => {
  const { user } = useContext(userContext);
  const selectedStoreId = useSelectedStore();
  const cellClassName = 'px-4 py-3 border-b border-gray-300';
  const { direction, lang } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const replace = useReplaceParams();
  const { data, mutate, isLoading } = useFetchVerdDrivers({ storeId: selectedStoreId });
  const updateStoreDriver = useUpdateStoreDriver();
  const deleteStoreDriver = useDeleteStoreDriver();

  const head = [
    translations.FULL_NAME,
    translations.PHONE_NUMBER,
    translations.JOB_TYPE,
    translations.STATUS,
    translations.ACTIONS,
  ];
  const isMobile = useMobile();
  const [isSomethingLoading, setIsSomethingLoading] = useState();
  const [isBetaAlertVisible, setIsBetaAlertVisible] = useState(true);

  const handleRiderDeletion = async rider => {
    setIsSomethingLoading(true);
    const response = await deleteStoreDriver({
      id: rider.id,
      userId: user.id,
    });
    if (response.errors?.length) {
      response.errors.map(error => notifications.show(error.message, 'error'));
    }
    mutate().finally(() => {
      setIsSomethingLoading(false);
    });
  };

  const isRidersLoading = isLoading || isSomethingLoading;

  return (
    <Layout
      top={
        <>
          {isBetaAlertVisible && <BetaAlert onClose={() => setIsBetaAlertVisible(false)} />}
          <Breadcrumbs
            links={[paths.verd]}
            path={translations.breadcrumbs.VERD}
            right={
              <Link to={paths.createRider}>
                <Button kind="primary">
                  <AddIcon className={cx(direction === 'ltr' ? 'mr-2' : 'ml-2')} />
                  <Text value={translations.ADD_NEW_RIDER} />
                </Button>
              </Link>
            }
          />
        </>
      }
    >
      <div style={{ direction }}>
        <div className="pb-12">
          {!data ? (
            <Spinner />
          ) : !data.length ? (
            'No Riders'
          ) : isMobile ? (
            <LoadingContainer isLoading={isRidersLoading}>
              <MobileList handleRiderDeletion={handleRiderDeletion} riders={data} />
            </LoadingContainer>
          ) : (
            <LoadingContainer isLoading={isRidersLoading}>
              <div className="flex flex-row mb-4">
                <EnableVerdSetting />
              </div>
              <table className="w-11/12 border border-gray-300">
                <thead>
                  <tr className="font-semibold bg-gray-200">
                    {head.map(item => (
                      <td className="p-4" key={item}>
                        <Text value={item} />
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map(rider => (
                    <tr key={rider.id}>
                      <td className={`${cellClassName}`}>{rider.name}</td>
                      <td className={`${cellClassName}`}>{rider.phone}</td>
                      <td className={`${cellClassName}`}>{rider.jobType}</td>
                      <td className={`${cellClassName} capitalize`}>
                        <div className={cx('flex')}>
                          <DropDown
                            testId_openDropDown="rider-availability"
                            testId_selectedData="rider-availability-name"
                            testId_listData="rider-availability-list"
                            float={lang === 'ar' ? 'left' : 'right'}
                            noMargin
                            full
                            alwaysShowIcon
                            name="rider-availability"
                            optionSelected={String(!!rider.onShift)}
                            onSelect={async newAvailability => {
                              setIsSomethingLoading(true);
                              await updateStoreDriver({
                                storeId: selectedStoreId,
                                id: rider.id,
                                onShift: newAvailability === RIDER_AVAILABILITY.AVAILABLE,
                              });
                              mutate().finally(() => {
                                setIsSomethingLoading(false);
                              });
                            }}
                            data={[
                              { id: RIDER_AVAILABILITY.AVAILABLE, title: <Text value={translations.AVAILABLE} /> },
                              { id: RIDER_AVAILABILITY.UNAVAILABLE, title: <Text value={translations.OFF_DUTY} /> },
                            ]}
                            icon="keyboard_arrow_down"
                            containerStyle={{ backgroundColor: rider.onShift ? '#ecf7ed' : '#ffeff1' }}
                            responsiveLabel
                            noBorder
                            itemColor={rider.onShift ? 'text-zyda-green-600' : 'text-zyda-red-600'}
                            isTracked
                          />
                        </div>
                      </td>
                      <td className={cx(`${cellClassName} w-px`)}>
                        <div className="flex items-center">
                          <div className={cx(direction === 'ltr' ? 'ml-2' : 'mr-2')}>
                            <Modal>
                              {({ open, close }) => (
                                <More
                                  kind="action"
                                  iconSize="md"
                                  size="smIcon"
                                  className="border-none"
                                  icon="more_horiz"
                                  items={[
                                    {
                                      title: (
                                        <Link
                                          to={replace(paths.editRider, {
                                            omitQuery: true,
                                            params: { riderId: rider.id },
                                          })}
                                        >
                                          <Text value={translations.EDIT} />
                                        </Link>
                                      ),
                                      onClick: () => {
                                        replace(paths.editRider, {
                                          omitQuery: true,
                                          params: { riderId: rider.id },
                                        });
                                      },
                                    },
                                    {
                                      title: <Text value={translations.DELETE} />,
                                      disabled: rider.assignedOrdersCount > 0,
                                      onClick: () => {
                                        open({
                                          title: rider.name,
                                          body: (
                                            <DeleteRider
                                              rider={rider}
                                              onClose={close}
                                              handleRiderDeletion={handleRiderDeletion}
                                            />
                                          ),
                                          size: 'max-w-md',
                                        });
                                      },
                                    },
                                  ]}
                                />
                              )}
                            </Modal>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </LoadingContainer>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default RidersScreen;
