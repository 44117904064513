import React, { useContext, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ExclamationIcon } from '@heroicons/react/outline';

import { useSelectedStore } from 'hooks';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { Label, Container, Row, Footer } from 'components/form/generic';
import { CheckboxAlt, Input, RadioList } from 'components/form/elements';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { ORDER_STATUS } from 'constants/order';
import { CANCEL_REASON, CANCEL_OPTIONS } from 'constants/cancel';
import RefundedOrder from 'components/common/orders/RefundOrder';
import RefundOptions from 'components/common/orders/RefundOptions';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';
import { ORDER } from '../../../../schemas';
import * as schemas from './schemas';
import { REFUND_TYPE_ENUM, initialValues, validationSchema } from './data';
import { ORDERS } from '../../../../../Frame/schemas';
import TitleOrderNumber from '../../../TitleOrderNumber';

const CancelOrderBody = ({
  orderId,
  onCancel,
  status,
  isCourierNotDeclinedTheRequest,
  isCourierSupportCancellation,
  orderDeliveryCourierName,
  ordersVariables,
  open,
}) => {
  const [refundCheckbox, setRefundCheckbox] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [orderfastWalletRefundData, setOrderfastWalletRefundData] = useState({
    refundType: REFUND_TYPE_ENUM.ORDERFAST_WALLET,
    compensationAmount: null,
  });
  const { lang, direction, translate } = useContext(localeContext);
  const isArabic = lang === 'ar';
  const notifications = useContext(notificationsContext);
  const { selectedStore, hasRole } = useContext(userContext);
  const storeId = useSelectedStore();
  const { data: orderData } = useQuery(ORDER, { variables: { storeId, orderId } });
  const { order: orderDetails } = orderData || {};
  const { number: orderNumber, total: orderTotal, paymentStatus: orderPaymentStatus, paidThrough: orderPaidThrough } =
    orderDetails || {};
  const orderCurrency = lang === 'en' ? selectedStore.currency.titleEn : selectedStore.currency.titleAr;
  const orderTotalWithCurrecy = `${orderCurrency} ${orderTotal}`;
  const refundTo =
    orderfastWalletRefundData.refundType === REFUND_TYPE_ENUM.ORDERFAST_WALLET
      ? translate(translations.ORDERFAST_WALLET)
      : translate(translations.CREDIT_CARD);
  const cancellationWarningText = isCourierSupportCancellation
    ? translations.CANCELLATION_COURIER_WARNING
    : translations.NO_SUPPORT_CANCELLATION_COURIER_WARNING;
  const updateStatusUpdateCache = (
    cache,
    {
      data: {
        updateOrderStatus: { status: newStatus },
      },
    },
  ) => {
    const orders = cache.readQuery({
      query: ORDERS,
      variables: ordersVariables,
    });
    if (orders) {
      const statuses = orders.orders.statusCount;
      const oldStatus = orders.orders.orders.find(({ id: oId }) => oId === orderId).status;
      cache.writeQuery({
        query: ORDERS,
        variables: ordersVariables,
        data: {
          orders: {
            ...orders.orders,
            statusCount:
              oldStatus !== newStatus
                ? {
                    ...statuses,
                    [status]: statuses[status] - 1,
                    [newStatus]: statuses[newStatus] + 1,
                  }
                : { ...statuses },
          },
        },
      });
    }
  };
  useEffect(() => {
    if (refundCheckbox && openRefund) {
      open({
        title: (
          <TitleOrderNumber
            refundType={translate(
              orderfastWalletRefundData.refundType === REFUND_TYPE_ENUM.ORDERFAST_WALLET
                ? translations.ORDERFAST_WALLET
                : translations.CREDIT_CARD,
            )}
            orderNumber={orderNumber}
          />
        ),
        body: (
          <RefundedOrder
            orderId={orderId}
            onCancel={onCancel}
            refundTo={refundTo}
            compensationAmount={orderfastWalletRefundData.compensationAmount}
            currency={orderCurrency}
            orderTotal={orderTotal}
            refundType={orderfastWalletRefundData.refundType}
          />
        ),
        size: 'max-w-sm',
      });
    }
  }, [refundCheckbox, openRefund]);

  useEffect(() => {
    if (orderfastWalletRefundData.refundType !== REFUND_TYPE_ENUM.ORDERFAST_WALLET)
      setOrderfastWalletRefundData(prevState => ({
        ...prevState,
        compensationAmount: null,
      }));
  }, [orderfastWalletRefundData.refundType]);

  const [cancel] = useMutation(schemas.CANCEL, {
    update: updateStatusUpdateCache,
    onError: () => {
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    },
  });
  const handleSubmit = async data => {
    if (refundCheckbox) setOpenRefund(true);
    const payload = {
      variables: {
        storeId,
        orderId,
        ...data,
        status: ORDER_STATUS.CANCELED,
      },
    };
    await cancel(payload);
    if (!refundCheckbox) onCancel();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <div className="px-6">
            {isCourierNotDeclinedTheRequest && (
              <div className={cx('flex items-center bg-zyda-yellow-50 px-4 py-2 mb-4 -mt-4 -mx-5 -mx-6')}>
                <ExclamationIcon className={cx(isArabic ? 'ml-2' : 'mr-2', 'w-6 h-6 text-zyda-yellow-500')} />
                <Text value={cancellationWarningText} payload={orderDeliveryCourierName} />
              </div>
            )}
            <Container>
              <Row>
                <div style={{ direction }}>
                  <Label title={<Text value={translations.REASON} className="font-medium" />}>
                    <div>
                      <div className="mb-4">
                        <Field type="text" name="cancellationReason" component={RadioList} items={CANCEL_OPTIONS} />
                      </div>
                      <Field
                        name="otherReason"
                        component={Input}
                        disabled={values.cancellationReason !== CANCEL_REASON.OTHER}
                        placeholder={
                          lang === 'en'
                            ? translations.ENTER_CANCELLATION_REASON[0]
                            : translations.ENTER_CANCELLATION_REASON[1]
                        }
                      />
                    </div>
                  </Label>
                  {(hasRole('owner') || hasRole('manager')) &&
                    [ORDER_STATUS.PAID, ORDER_STATUS.REFUND_FAILED].includes(orderPaymentStatus) &&
                    ![
                      PAYMENT_METHODS_ENUMS.CARD_ON_DELIVERY,
                      PAYMENT_METHODS_ENUMS.CASH_CAM,
                      PAYMENT_METHODS_ENUMS.CASH,
                    ].includes(orderPaidThrough) && (
                      <>
                        <div
                          onClick={() => setRefundCheckbox(prevState => !prevState)}
                          className="mb-2 mt-6"
                          aria-hidden="true"
                        >
                          <Field
                            title={<Text value={translations.REFUND_FULL(orderTotalWithCurrecy)} />}
                            name="refund-auto"
                            component={CheckboxAlt}
                            testId="refund-auto"
                          />
                        </div>
                        {refundCheckbox && (
                          <div className="py-4">
                            <RefundOptions
                              lang={lang}
                              orderPaidThrough={orderPaidThrough}
                              orderTotalWithCurrecy={orderTotalWithCurrecy}
                              orderfastWalletRefundData={orderfastWalletRefundData}
                              setOrderfastWalletRefundData={setOrderfastWalletRefundData}
                              currency={orderCurrency}
                              orderTotal={orderTotal}
                            />
                          </div>
                        )}
                      </>
                    )}
                </div>
              </Row>
            </Container>
          </div>
          <Footer modalFooter>
            <div className="ml-auto w-full">
              <div className="w-full flex items-center justify-between">
                <Button onClick={onCancel} type="button" kind="tertiary">
                  <Text value={translations.DISMISS} />
                </Button>
                <Button isSpinning={isSubmitting} kind="secondaryError">
                  <Text value={translations.CONFIRM_CANCELLATION} />
                </Button>
              </div>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  );
};

CancelOrderBody.propTypes = {
  orderId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  status: PropTypes.string,
  isCourierNotDeclinedTheRequest: PropTypes.bool,
  isCourierSupportCancellation: PropTypes.bool,
  orderDeliveryCourierName: PropTypes.string,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.number,
    sort: PropTypes.objectOf({ order: PropTypes.string, field: PropTypes.string }),
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  open: PropTypes.func.isRequired,
};
export default CancelOrderBody;
