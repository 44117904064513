import { InMemoryCache } from '@apollo/client/cache';

const cache = new InMemoryCache({
  typePolicies: {
    VariantPropertyValue: {
      keyFields: ['id', 'quantity'],
    },
    Property: {
      keyFields: ['id', 'propertyValues'],
    },
    UserData: {
      merge: true,
    },
  },
});

export default cache;
