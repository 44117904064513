import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Box } from 'components/kit';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import { ReactComponent as Card } from 'assets/card-delivery.svg';
import * as schemas from '../schemas';
import ActionButton from '../ActionButton';
import ManualPaymentMethodCard from './ManualPaymentMethodCard';

const CardDeliverySection = ({ settings, storeId, refetch, numOfEnabledOnlineMethods }) => {
  const { lang } = useContext(localeContext);
  const { selectedStore, user } = useContext(userContext);

  const [updateEnabled, { loading: updating }] = useMutation(schemas.UPDATE_CARD_ON_DELIVERY_ENABLED, {
    variables: { storeId },
    onCompleted: refetch,
  });

  const disabled = settings.cardondeliveryPaymentEnabled && !settings.cashPaymentEnabled && !numOfEnabledOnlineMethods;

  return (
    <div className="w-full lg:w-1/3 p-3 pl-0">
      <Box
        title={<Text value={translations.CARD_DELEVERY} />}
        description={<Text className="mt-3" value={translations.CARD_DELEVERY_DESCRIPTION} />}
        icon={<Card className={lang === 'en' ? 'mr-2' : 'ml-2'} />}
        displayBorderBottom={false}
        action={
          <ActionButton
            testId="enable-card-delivery"
            isSpinning={updating}
            onClick={() =>
              updateEnabled({
                variables: { enabled: !settings.cardondeliveryPaymentEnabled },
              })
            }
            disabled={(!user.isSupport && !!selectedStore.isCommissionBased) || disabled}
          >
            <Text value={settings.cardondeliveryPaymentEnabled ? translations.DISABLE : translations.ENABLE} />
          </ActionButton>
        }
        body={<ManualPaymentMethodCard />}
      />
    </div>
  );
};

CardDeliverySection.propTypes = {
  settings: PropTypes.shape({
    cardondeliveryPaymentEnabled: PropTypes.bool.isRequired,
    cashPaymentEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  storeId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  numOfEnabledOnlineMethods: PropTypes.number.isRequired,
};

export default CardDeliverySection;
