export const SAME_DAY = 'same_day';
export const NEXT_DAY = 'next_day';
export const DELIVERY_DURATION_TEMPLATE = 'deliveryDurationTemplate';

export const POS = 'pos';
export const PARTNER = 'partner';
export const COURIER = 'courier';
export const DELIVERECT = 'deliverect';

export const TAX_INCLUSIVE = 'TAX_INCLUSIVE';
export const TAX_EXCLUSIVE = 'TAX_EXCLUSIVE';

export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const PARTIALLY_CONNECTED = 'PARTIALLY_CONNECTED';
export const OCIMS = 'ocims';
