import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import cx from 'classnames';

import * as translations from 'constants/translations';
import { breadcrumbs } from 'constants/translations';
import { PAYMENT_GATEWAY_LINKS } from 'constants/helperLinks';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import { Spinner } from 'components/kit';
import { Text } from 'components/service';
import FeatureAccess from 'common/lib/FeatureAccess';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as featureBlockingContext } from 'context/feature-blocking';
import { useSelectedStore } from 'hooks/index';
import * as schemas from './schemas';
import CashDeliverySection from './ManualPaymentMethods/CashDeliverySection';
import CardDeliverySection from './ManualPaymentMethods/CardDeliverySection';
import OnlinePaymentGatewayCard from './OnlinePaymentGateways/OnlinePaymentGatewayCard';

const PaymentGateways = () => {
  const storeId = useSelectedStore();
  const { lang } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { featureAccess } = useContext(featureBlockingContext);
  const location = useLocation();
  const featureAccessMap = featureAccess
    .get(ACCESS_MAP_KEYS.FEATURES)
    ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id));

  const { data: { paymentCredentials, settings } = {}, refetch, loading } = useQuery(schemas.PAYMENT_CREDENTIALS, {
    variables: { storeId },
    fetchPolicy: 'cache-and-network',
  });

  const { data: { paymentGateways } = {} } = useQuery(schemas.PAYMENT_GATEWAYS, {
    variables: { countryId: parseInt(selectedStore.countryId) },
    fetchPolicy: 'cache-and-network',
  });

  const numOfEnabledOnlineMethods =
    paymentCredentials?.restaurantPaymentMethods?.filter(method => method.enabled === true)?.length || 0;

  return (
    <Layout top={<Breadcrumbs helperLinks={PAYMENT_GATEWAY_LINKS} path={breadcrumbs.SETUP_PAYMENT_METHODS} />}>
      <div className={cx('px-3 pb-6', lang === 'ar' && 'flex-row-reverse')}>
        {loading || !paymentGateways ? (
          <Spinner />
        ) : (
          <>
            <Text className="text-lg font-semibold" value={translations.ONLINE_PAYMENT_METHODS} />
            <div className={cx('flex flex-wrap', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
              {paymentGateways.map(gateway => (
                <OnlinePaymentGatewayCard paymentCredentials={paymentCredentials} gateway={gateway} key={gateway?.id} />
              ))}
            </div>
            <Text className="text-lg font-semibold" value={translations.MANUAL_PAYMENT_METHODS} />
            <div className={cx('flex flex-wrap', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
              <CashDeliverySection
                storeId={storeId}
                refetch={refetch}
                settings={settings}
                numOfEnabledOnlineMethods={numOfEnabledOnlineMethods}
                isBlocked={featureAccessMap?.CASH_ON_DELIVERY?.isBlocked}
              />
              <CardDeliverySection
                storeId={storeId}
                refetch={refetch}
                settings={settings}
                numOfEnabledOnlineMethods={numOfEnabledOnlineMethods}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default PaymentGateways;
