import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { ORDER_STATUS } from 'constants/order';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import { Button } from 'components/kit';
import * as translations from 'constants/translations';
import CancelOrderBody from './CancelOrderBody';
import TitleOrderNumber from '../../TitleOrderNumber';

const CancelOrder = ({
  isPosBusiness,
  order,
  shouldDisableActionBtns,
  open,
  ordersVariables,
  close,
  status: CanceledOrderStatus,
  isMobile,
}) => {
  const tabletToSmallerMQ = useMediaQuery({ maxWidth: 767 });
  const { paymentStatus, status, inBetweenTransitions, number, id } = order || {};
  const hasCourier = order.deliveryCourier && !order.deliveryCourier.isInternalDelivery;
  const deliveryOrderStatus = order.deliveryCourier?.deliveryOrderStatus;
  const isCourierNotDeclinedTheRequest = hasCourier && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;
  const isCourierSupportCancellation = order.deliveryCourier?.supportCancellation;
  const orderDeliveryCourierName = order.deliveryCourier?.courierDetails?.displayNameEn;

  return (
    ![ORDER_STATUS.CANCELED].includes(status) &&
    ![
      ORDER_STATUS.WAITING_FOR_PAYMENT,
      ORDER_STATUS.PAYMENT_FAILED,
      ORDER_STATUS.REDIRECT_URL,
      ORDER_STATUS.PAYMENT_EXPIRED,
      ORDER_STATUS.IFRAME_URL,
    ].includes(paymentStatus) && (
      <Button
        disabled={shouldDisableActionBtns || inBetweenTransitions}
        onClick={() =>
          open({
            title: <TitleOrderNumber isCancel orderNumber={number} />,
            body: (
              <CancelOrderBody
                ordersVariables={ordersVariables}
                status={CanceledOrderStatus}
                isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
                isCourierSupportCancellation={isCourierSupportCancellation}
                orderDeliveryCourierName={orderDeliveryCourierName}
                orderId={id}
                onCancel={close}
                open={open}
              />
            ),
            size: 'max-w-sm',
          })
        }
        kind="secondaryError"
        className="self-end"
        size="base"
        full={status === ORDER_STATUS.SUBMITTED && hasCourier && !isPosBusiness && isMobile && tabletToSmallerMQ}
        data-testid="cancel-order-button"
      >
        <Text value={translations.CANCEL_ORDER} />
      </Button>
    )
  );
};

CancelOrder.propTypes = {
  isPosBusiness: PropTypes.bool,
  order: PropTypes.shape({
    paymentStatus: PropTypes.string,
    status: PropTypes.string,
    inBetweenTransitions: PropTypes.bool,
    number: PropTypes.string,
    id: PropTypes.number,
    isScheduled: PropTypes.bool,
  }),
  shouldDisableActionBtns: PropTypes.bool,
  open: PropTypes.func,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  close: PropTypes.func,
  status: PropTypes.string,
  isMobile: PropTypes.bool,
};
export default CancelOrder;
