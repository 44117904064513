import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { Box, Spinner } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { context as userContext } from 'context/user';
import * as schemas from '../schemas';
import CourierCard from '../Card';

function CourierIntegration({ open, close, closeable }) {
  const {
    selectedStore: { countryCode },
  } = useContext(userContext);
  const { data, loading } = useQuery(schemas.LIST_COURIERS, {
    variables: {
      businessType: THIRD_PARTY_TYPE_ENUM.Delivery,
      isPublished: true,
      countryCode,
    },
  });

  return (
    <>
      {!!data?.couriers.length && (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Box
              title={<Text className="font-semibold text-lg -mb-2" value={translations.DELIVERY_COURIER} />}
              body={
                <div className="pb-2 px-4">
                  {data?.couriers.map(courier => (
                    <div key={courier.name} className="mt-4">
                      <CourierCard
                        onOpen={open}
                        onClose={close}
                        closeable={closeable}
                        courier={courier}
                        courierType={THIRD_PARTY_TYPE_ENUM.Delivery.toLocaleLowerCase()}
                      />
                    </div>
                  ))}
                </div>
              }
            />
          )}
        </>
      )}
    </>
  );
}

CourierIntegration.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
};

export default CourierIntegration;
